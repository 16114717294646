import React, { useState } from "react"
import useFirestoreId from '../../modules/useFireStoreId';
import { Container } from 'react-bootstrap';
import './blogs.scss'
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import { Button } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import fb from '../../images/fb.png';
import twitter from '../../images/twitter.png';
import linkedin from '../../images/linked.png';
import quara from '../../images/quara.png';
import link from '../../images/link.png';
import ContactModal from '../../components/ContactModal';

const useStyles = makeStyles({
    btRoot: {
        borderRadius: 20,
        fontSize: "10px",
        marginLeft: '5px',
        marginTop: '5px'
    }
});


export default function Blogs() {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const url = typeof window !== 'undefined' ? window.location.pathname : '';
    const urlParams = url.split('/');
    const blogId = urlParams[urlParams.length - 1];
    const { doc } = useFirestoreId(blogId);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Layout>
            <SEO title={doc ? "Blog | " + doc.title : "Blog"} description="" />
            <ContactModal open={open} handleClose={handleClose} />
            <Container className="mainwrapper blogsWrapper">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={8}>
                        {doc &&
                            <div>
                                <div className="blog-heading">{doc.title}</div>
                                <p className="mb-4 blog-sub-heading">By <span style={{ color: "#4e069e" }}><strong>{doc.author}</strong></span> on {moment(doc.date).format('ll')}</p>
                                <div className="blog-main-text" dangerouslySetInnerHTML={{ __html: doc.content }}></div>
                            </div>
                        }
                        <Grid>
                            <Paper variant="outlined" className="aiInnovations" >
                                <h6>Are you ready to be part of AI Innovations?
                                </h6>
                                <h3>Start your trial and get <br></br>
1500 annotations for free</h3>
                                <Button onClick={handleOpen} className="contactUs">CONTACT US</Button>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper variant="outlined" className="newsletter" >
                            <h3>Subscribe for our Newsletter</h3>
                            <h5>Email Address</h5>
                            <Input placeholder="" className="inputBox" />
                            <Button className="btSubscribe">Subscribe</Button>
                        </Paper>
                        <Grid className="tags">
                            <h3>ARTICLE TAGS</h3>
                            {
                                !_.isEmpty(doc) &&
                                doc.tags.map((val, index) => {
                                    return (
                                        <Button key={index} className={classes.btRoot} variant="outlined" color="primary">
                                            {val}
                                        </Button>
                                    )
                                })
                            }
                            <h3>SHARE</h3>
                            <ul>
                                <li><img src={fb} alt="fb" /></li>
                                <li><img src={linkedin} alt="fb" /></li>
                                <li><img src={twitter} alt="fb" /></li>
                                <li><img src={quara} alt="fb" /></li>
                                <li><img src={link} alt="fb" /></li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}


